// @flow

import React from 'react'
import { css } from '@emotion/core'
// import styled from '@emotion/styled'
import { mq } from '@/styles/theme'
import FormLayout from '@/layouts/FormLayout'
import { Title, Copy, Back } from '@/components/Form'
import ContactForm from '@/forms/contact'

const ContactFormColumnStyles = css`
  margin-top: 190px;
  min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;

  ${mq.lessThan('medium')`
    display: none;
  `};
`

const ContactFormMobileOnlyStyles = css`
  display: none;

  ${mq.lessThan('medium')`
    display: block;
  `};
`

function ContactPage() {
  return (
    <FormLayout>
      <div>
        <Back>← Back</Back>
        <Title>Contact Us</Title>
        <Copy>
          <p>
            If you’re interested in our senior living and health care options or
            have any other general inquiries, please call{' '}
            <a href="tel:808-533-5416">
              <b>(808) 533-5416</b>
            </a>
          </p>
          <div css={ContactFormMobileOnlyStyles}>
            <ContactForm action="/contact-page-submission" />
          </div>
          <p>
            or may send an email to{' '}
            <u>
              <a href="mailto:info@arcadia.org">
                <b>info@arcadia.org</b>
              </a>
            </u>
          </p>
          <p>
            Mailing Address:
            <br />
            Arcadia Family of Companies
            <br />
            1434 Punahou St
            <br />
            Honolulu, HI 96822
          </p>
        </Copy>
      </div>
      <div css={ContactFormColumnStyles}>
        <ContactForm action="/contact-page-submission" />
      </div>
    </FormLayout>
  )
}

export default ContactPage
